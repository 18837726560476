.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    width: 120px;
    visibility: hidden;
    background-color: #79b8ce;
    color: #d3f1fb;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: -20px;
    left: 105%;
    opacity: 90%;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

#copied-message {
  width: 120px;
  background-color: #79b8ce;
  color: #d3f1fb;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  z-index: 1;
  opacity: 90%;
}

.close-card {
  top: 5px;
  position: absolute;
  right: 10px;
}

/* Style the modal */
.loader-modal {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Modal Content */
  .loader-modal-content {
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    border-radius: 5px;
    display: flex;
    align-items: center;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: #fefefe;
  }
  
  .loader-modal-text {
    margin-left: 14px;
    font-size: 24px;
  }
  
  /* Create the circle loader with pure CSS */
  .loader {
    width: 50px;
    height: 50px;
    border: 8px solid #ddd;
    border-top: 8px solid #2196f3;
    border-bottom: 8px solid #2196f3;
    border-radius: 50%;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

.error-message {
  color: red;
}